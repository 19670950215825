import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import React from "react"

const Card = props => {
  const { showName, slug, showLogo, shortSummary } = props

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden group">
      <Link to={`/${slug}`}>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
          <Img
            fluid={showLogo.localFile.childImageSharp.fluid}
            alt={showName}
          />
        </div>
        <div className="p-4 sm:p-5">
          <h1 className="sm:text-lg text-gray-900 font-semibold">{showName}</h1>
          <p className="text-sm sm:text-base text-gray-700">
            {shortSummary?.shortSummary}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default Card

export const query = graphql`
  fragment ShowCard on ContentfulShow {
    id
    showName
    slug
    shortSummary {
      shortSummary
    }
    showLogo {
      localFile {
        childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
