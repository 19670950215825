import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  const shows = [...data?.show?.nodes]
  shows.sort((a, b) => a.order - b.order)

  return (
    <Layout>
      <SiteMetadata title="Home" description="Portfolio of John Doe" />

      <Hero />

      <div className="bg-gray-100 py-12 lg:py-16">
        <h2 className="text-3xl sm:text-4xl font-extrabold leading-tight tracking-tight text-gray-900 container mb-6">
          Shows
        </h2>
        {shows && shows.length > 0 ? (
          <Cards items={shows} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    show: allContentfulShow {
      nodes {
        order
        ...ShowCard
      }
    }
  }
`
